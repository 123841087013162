import "./scss/app.scss"

window.jQuery = require("jquery")
const $ = window.jQuery

import "lightgallery"
import "lg-thumbnail"
import "lg-fullscreen"
import "popper.js"
import "bootstrap"
import "bootstrap/js/dist/tab"
import "jquery-validation/dist/jquery.validate"

require("./scripts/freewall")
require("./scripts/slider")
require("./scripts/smooth-scroll")
require("./scripts/form-validation")

$.urlParam = function (name) {
  var results = new RegExp('[\?&]' + name + '=([^&#]*)')
                    .exec(window.location.search);

  return (results !== null) ? decodeURIComponent(results[1]).split('+').join(' ') || 0 : false;
}

$(document).ready(() => {
  $("#myModal").on('click', function() {
    $(this).css('display', 'none');
  });
  let succesfulMessage = $.urlParam('message');
  let errorMessage = $.urlParam('errormessage');
  if (succesfulMessage || errorMessage) {
    let message = succesfulMessage ? succesfulMessage : errorMessage;
    $("#customHeaderModal").css('background-color', succesfulMessage ? '' : 'red' );
    $("#modalText").text(message);
    $("#myModal").css('display', 'flex');
  }


  const link = document.createElement("a");
  link.setAttribute("href", "impressum.html");
  link.textContent = "Impressum";
  $(".impressum").append(link);

  const toggleShowMenu = () => {
    $("#container").toggleClass("show-menu")
  }
  $("#hamburger-menu-button").click(toggleShowMenu)

  $(".menu-mobile").click(toggleShowMenu);
  $(".blured-overlay").click(toggleShowMenu);

  // setupGallery();
  
  // $(".carousel").carousel({
  //   interval: 2000,
  // })

  $(".promo-content").slider({
    config: "aaaa",
  })
})

const setupGallery = function () {

  var temp =
    "<div class='brick' style='width:{width}px;'><a href='https://via.placeholder.com/300x400'><img src='https://via.placeholder.com/300x400' width='100%'></a></div>"
  var w = 1,
    h = 1,
    html = "",
    limitItem = 10
  for (var i = 0; i < limitItem; ++i) {
    w = (1 + 3 * Math.random()) << 0
    html += temp.replace(/\{width\}/g, w * 150)
    // .replace("{index}", i + 1)
  }
  $("#tab-residential").html(html)

  var wall = new Freewall("#tab-residential")
  wall.reset({
    selector: ".brick",
    animate: true,
    cellW: 150,
    cellH: "auto",
    onResize: function () {
      wall.fitWidth()
    },
  })

  var images = wall.container.find(".brick")
  images.find("img").ready(function () {
    wall.fitWidth()
  })

  $("#tab-residential").lightGallery()
}
