;(function ($) {
  $(document).ready(() => {
    const form = $("form[name='mail']")
    form.validate({
      ignore: ".ignore",
      // Specify validation rules
      rules: {
        // The key name on the left side is the name attribute
        // of an input field. Validation rules are defined
        // on the right side
        name: "required",
        email: {
          required: true,
          // Specify that email should be validated
          // by the built-in "email" rule
          email: true,
        },
        topic: {
          required: true,
          minlength: 5,
        },
        phone: "required",
        hiddenRecaptcha: {
          required: function () {
              if (grecaptcha.getResponse() == '') {
                  return true;
              } else {
                  return false;
              }
          }
        }
      },
      // Specify validation error messages
      messages: {
        name: "Bitte tragen Sie Ihren vollen Namen ein",
        email: "Bitte eine gültige E-Mail-Adresse eintragen",
        topic: {
          required: "Bitte geben Sie ein Thema an",
          minlength: "Ihr Thema muss mindestens 5 Zeichen lang sein",
        },
        content: "Bitte geben Sie eine Nachricht ein",
      },
      // Make sure the form is submitted to the destination defined
      // in the "action" attribute of the form when valid
      submitHandler: function (form) {
        if(form.valid()) {
          e.preventDefault();
        } else {
          $(form).submit()
        }
        
      },
    })
  })
})(window.jQuery)
